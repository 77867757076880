<template>
	<div class="look-box pd16_15">
		<div class="flex alcenter space">
			<div class="flex alcenter">
				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/mall_home_album@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'门店相册'}}</span>
			</div>
		
			<div>
				<span class="ft12 cl-main">查看全部</span>
				<span class="iconfont iconarrowright_black ft12 cl-main ml5"></span>
			</div>
		</div>
		<div class="mt16">
			<a-spin :spinning="loading">
				<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
				<div v-else class="flex">
					<div v-for="(item,index) in calldata" :key="index" :class="{ml15:index > 0}" class="look-img-box">
						<img :src="item.photo" />
						<div class="tag">{{item.length}}张</div>
					</div>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
	
		data(){
			return {
				loading:false,
				
			}
		},
		computed:{
			calldata(){
				return this.datas.datas;
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.look-box{
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
		overflow: hidden;
	}
	
	.look-img-box{
		width:95px;
		height: 95px;
		position: relative;
	}
	
	.look-img-box img{
		width:95px;
		height: 95px;
		border-radius: 4px;
		background: #f2f2f2;
		object-fit: fill;
	}
	.look-img-box .tag{
		position: absolute;
		right: 8px;
		bottom: 8px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 2px;
		padding: 1px 6px;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>