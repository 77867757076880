<template>
	<div class="mendian-box">
		<a-spin :spinning="loading">
			<div v-if="detail == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div>
			<div v-else>
				<div class="pd20_15">
					<div class="flex alcenter">
						<img class="company-logo" :src="detail.logo" />
						<div class="ml15">
							<div class="ft16 cl-main ftw600">{{detail.name}}</div>
							<div class="mt4 ft12 cl-notice">距您 5km</div>
						</div>
					</div>
					<div  class="text-over cl-main mt16">
						<span class="iconfont iconaddress ft14"></span>
						<span class="ft12 ml10">{{detail.address}}</span>
					</div>
					<div class="text-over cl-main mt16">
						<span class="iconfont icontime ft14"></span>
						<span class="ft12 ml10">营业时间：{{detail.business_hours}}</span>
					</div>
				</div>
				<div class="line"></div>
				<div class="flex">
					<div  class="col2 pd16_15 text-center">
						<span class="iconfont iconposition cl-blue ft20"></span>
					</div>
					<div  class="col2 pd16_15 text-center" style="border-left: 1px solid #F0F0F0;">
						<span class="iconfont iconcall cl-green ft20"></span>
					</div>
				</div>
			</div>
		</a-spin>	
	</div>
</template>

<script>
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return {
				loading:false,
			}
		},
		computed:{
			detail(){
				return  this.datas.datas[0];
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.company-logo{
		width: 40px;
		height: 40px;
		border-radius: 4px;
	}	
	.mendian-box{
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
		overflow: hidden;
	}
</style>